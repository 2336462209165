import React from "react"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import InfoRow from "../../zzz/organisms/InfoRow"
import Reviews from "../../components/Reviews"

const ReviewsPage = ({ location }) => {
  const data = useStaticQuery(PAGE)
  const logos = data.homePage.home_page.publicationsRow
  const about = data.reviewsPage
  const seo = data.reviewsPage.seo
  const reviews = data.reviewsPage.reviews

  return (
    <Layout location={location}>
      <SEO title="Reviews" location={location} post_id={454} seo={seo} />
      <Heading
        subTitle={about.aboutUs.aboutUsSubTitle}
        fluid={about.featuredImage.imageFile.localFile.fluid}
        location={location}
      />
      <Wrap>
        <Inner>
          <Body>
            <h2>{reviews.reviewTitle}</h2>
            <p>{reviews.reviewBody}</p>
          </Body>
        </Inner>
      </Wrap>
      {/* <Wrap> */}
      <Quotes>
        <Inner>
          <Reviews interval={10000}>
            {reviews.reviewEntries.map(({ content, name }, i) => (
              <div
                className={`${i} reviewDivheights`}
                key={i}
                reviedata={i}
                style={{ padding: "20px" }}
                id="reviewItem"
              >
                {/* <Appostrophe>"</Appostrophe> */}
                <h2>
                  <span>&quot;</span>
                  {content}
                </h2>
                <p> - {name}</p>
              </div>
            ))}
          </Reviews>
        </Inner>
      </Quotes>
      {/* </Wrap> */}
      <InfoRow data={logos} />
    </Layout>
  )
}

export default ReviewsPage

const Appostrophe = styled.div`
  font-size: 28px;
  color: red;
`
const Body = styled.div`
  text-align: center;
  padding: 40px 0;
  h2 {
    border-bottom: 2px solid red;
    margin-bottom: 20px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 40px;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
`
const Quotes = styled.div`
  background-color: #223247;
  padding: 40px 0;
  position: relative;
  * {
    color: white;
  }
  h2 {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 18px;
    }
  }
  p {
    font-size: 20px;
  }
  span {
    font-size: 80px;
    color: red;
    line-height: 0px;
    font-weight: 900;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 35px;
    }
  }
`

const Wrap = styled.div`
  background: #f6f6f6;
  padding: 50px 0;
`

const PAGE = graphql`
  {
    reviewsPage: localWpGraphQlPages(pageId: { eq: 454 }) {
      reviews {
        reviewTitle
        reviewBody
        reviewQuote
        reviewEntries {
          content
          name
        }
        reviewImage {
          sourceUrl
          mediaItemId
          imageFile {
            localFile {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      aboutUs {
        aboutUsSubTitle
      }
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }

    homePage: localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        publicationsRow {
          title
          subTitle
          logos {
            link
            image {
              sourceUrl
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    fixed(width: 150) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
